import {
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { TFunction } from "i18next";
import { settings } from "../../../../../../customize/settings";
import { IDettaglio } from "../../../../../../interfaces/dettaglio/models";
import { ILottoImage } from "../../../../../../interfaces/lotto/models";
import { CellTipoLotto } from "../../../../shared/tableCells";
import { CellThumbnail } from "../tableCells";
import { ICreateDefaultGridColumnsPros } from "./interfaces";

const i18_PATH = "prodotto.view.lottoDettagli.grids.defaultGridColumns.";

const createAllColumns = (
  t: TFunction<"translation", undefined, "translation">,
  onViewImage: (image: ILottoImage) => void
): GridColumns<IDettaglio> => {
  const columns: GridColumns<IDettaglio> = [
    {
      field: "tipoLotto",
      headerName: t(`${i18_PATH}Tipo`) ?? undefined,
      type: "actions",
      sortable: false,
      width: 60,
      renderCell: (params: GridRenderCellParams<unknown, IDettaglio>) => (
        <CellTipoLotto dettaglio={params.row} />
      ),
    },
    {
      field: "suffissoCodice",
      headerName: "",
      sortable: false,
      width: 10,
    },
    {
      field: "images",
      type: "actions",
      width: settings.item?.grid?.defaultColumnsThumbnailWidth ?? 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<unknown, IDettaglio>) => (
        <CellThumbnail images={params.row.images} onViewImage={onViewImage} />
      ),
    },
    {
      field: "magazzino",
      headerName: t(`${i18_PATH}Magazzino`) ?? undefined,
      sortable: false,
      valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) =>
        params?.row?.magazzino?.nome,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "zona",
      headerName: t(`${i18_PATH}Zona`) ?? undefined,
      sortable: false,
      valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) =>
        params?.row?.zona?.nome,
      minWidth: 80,
      flex: 1,
    },
    {
      field: "fila",
      headerName: t(`${i18_PATH}Fila`) ?? undefined,
      sortable: false,
      valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) =>
        params?.row?.fila?.nome,
      minWidth: 80,
      flex: 1,
    },
    {
      field: "bundle",
      headerName: t(`${i18_PATH}Bundle`) ?? undefined,
      type: "number",
      sortable: false,
      renderCell: (params: GridRenderCellParams<number, IDettaglio>) => (
        <b>{params.formattedValue}</b>
      ),
      width: 60,
    },
    {
      field: "lastreDa",
      headerName: t(`${i18_PATH}Da`) ?? undefined,
      type: "number",
      sortable: false,
      valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
        params?.row?.tipo_lotto?.nome === "Lastre"
          ? params?.row?.lastreDa
          : "-------",
      width: 60,
    },
    {
      field: "lastreA",
      headerName: t(`${i18_PATH}A`) ?? undefined,
      type: "number",
      sortable: false,
      valueGetter: (params: GridValueGetterParams<undefined, IDettaglio>) =>
        params?.row?.tipo_lotto?.nome === "Lastre"
          ? params?.row?.lastreA
          : "-------",
      width: 60,
    },
    {
      field: "pezzi",
      headerName: t(`${i18_PATH}Pezzi`) ?? undefined,
      type: "number",
      sortable: false,
      renderCell: (params: GridRenderCellParams<string, IDettaglio>) => (
        <b>{params.formattedValue}</b>
      ),
      width: 60,
    },
    {
      field: "misX",
      headerName: t(`${i18_PATH}Lun`) ?? undefined,
      type: "number",
      sortable: false,
      width: 80,
    },
    {
      field: "misY",
      headerName: t(`${i18_PATH}Alt`) ?? undefined,
      type: "number",
      sortable: false,
      width: 80,
    },
    {
      field: "misZ",
      headerName: t(`${i18_PATH}Spes`) ?? undefined,
      type: "number",
      sortable: false,
      width: 80,
    },
    {
      field: "quantitaUM",
      headerName: t(`${i18_PATH}Quantità`) ?? undefined,
      type: "number",
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => {
        const value = parseFloat(String(params.value).replaceAll(",", "."));

        const answer = Number(value.toFixed(2)).toLocaleString("it-IT");
        return answer;
      },
      width: 80,
    },
    {
      field: "um",
      headerName: "",
      sortable: false,
      valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) =>
        params?.value ? t(String(params.value)) : "",
      width: 15,
    },

    {
      field: "costo",
      headerName: t(`${i18_PATH}Costo`) ?? undefined,
      type: "number",
      valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) => {
        const costiDiretti = params?.row?.costiDiretti || 0;
        const costiPadre = params?.row?.costiPadre || 0;
        const quantity = params?.row?.quantitaUM || 1;
        return (costiDiretti + costiPadre) / quantity;
      },
      renderCell: (params: GridRenderCellParams<number>) => {
        const value = parseFloat(String(params.value).replaceAll(",", "."));
        return Number(value)
          ? Number(value.toFixed(2)).toLocaleString("it-IT")
          : "-------";
      },
      sortable: false,
      width: 120,
    },
    {
      field: "um_costo",
      headerName: "",
      sortable: false,
      valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) =>
        params?.row?.um ? t(String(params.row.um)) : "",
      width: 15,
    },
    {
      field: "prezzo",
      headerName: t(`${i18_PATH}Prezzo`) ?? undefined,
      type: "number",
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => {
        const value = parseFloat(String(params.value).replaceAll(",", "."));
        return Number(value)
          ? Number(value.toFixed(2)).toLocaleString("it-IT")
          : "-------";
      },
      width: 120,
    },
    {
      field: "um_prezzo",
      headerName: "",
      sortable: false,
      valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) =>
        params?.row?.um ? t(String(params.row.um)) : "",
      width: 15,
    },
    {
      field: "stato_lavorazione",
      headerName: t(`${i18_PATH}Lavorazione`) ?? undefined,
      valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) =>
        t(params?.row?.stato_lavorazione?.nome ?? ""),
      sortable: false,
      minWidth: 120,
      flex: 1,
    },
    {
      field: "disponibilita",
      headerName: t(`${i18_PATH}Disponibilita`) ?? undefined,
      sortable: false,
      valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) =>
        t(params?.row?.disponibilita?.nome ?? ""),
      minWidth: 120,
      flex: 1,
    },
    {
      field: "opzCliente",
      headerName: t(`${i18_PATH}Cliente`) ?? undefined,
      minWidth: 220,
      flex: 1,
      sortable: false,
    },
    {
      field: "agente",
      headerName: t(`${i18_PATH}Agente`) ?? undefined,
      valueGetter: (params: GridValueGetterParams<unknown, IDettaglio>) =>
        params?.row?.opzUser?.nome,
      minWidth: 220,
      flex: 1,
      sortable: false,
    },
    {
      field: "opzNote",
      headerName: t(`${i18_PATH}Note`) ?? undefined,
      sortable: false,
      flex: 1,
      minWidth: 380,
    },
  ];
  const columnsToHide = settings.item?.grid?.defaultColumnsToHide ?? [];
  return columns.filter((col) => !columnsToHide.includes(col.field));
};

export const createDefaultGridColumns = ({
  t,
  canViewCommercials,
  canViewStoragePlace,
  canViewCosts,
  canViewCommercialOptions,
  onViewImage,
}: ICreateDefaultGridColumnsPros): GridColumns<IDettaglio> => {
  let columns = createAllColumns(t, onViewImage);
  if (!canViewCosts)
    columns = columns.filter(
      (col) => col.field !== "costo" && col.field !== "um_costo"
    );
  if (!canViewCommercials)
    columns = columns.filter(
      (col) => col.field !== "prezzo" && col.field !== "um_prezzo"
    );
  if (!canViewStoragePlace)
    columns = columns.filter(
      (col) =>
        col.field !== "magazzino" &&
        col.field !== "zona" &&
        col.field !== "fila"
    );
  if (!canViewCommercialOptions)
    columns = columns.filter(
      (col) =>
        col.field !== "opzStato" &&
        col.field !== "opzCliente" &&
        col.field !== "agente" &&
        col.field !== "opzNote"
    );
  return columns;
};
